
import { Order } from '@/common/types/Order.type';
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';
import AppointmentEntry from '@/modules/consent/components/appointment-entry.component.vue';

@Options({
	name: 'AppointmentsView',
	components: { AppointmentEntry },
})
export default class AppointmentsView extends Vue {
	orderEntities: Order[] = [];
	searchInput = '';
	isLoading = true;
	showingAll = false;
	noBookingMsg = 'No upcoming bookings in the next hour. Tap button below to see all bookings for today.';

	created() {
		this.getCheckinOrders(false);
	}

	async getCheckinOrders(showAll: boolean) {
		this.isLoading = true;
		this.showingAll = showAll;
		apiService
			.get('getCheckinOrders', {
				clientLocationKey: kioskModule.clientLocationKey,
				orderKey: null,
				showAll: showAll,
			})
			.then((orders: Order[]) => {
				if (Array.isArray(orders) && orders.length) this.orderEntities = orders;
			})
			.finally(() => (this.isLoading = false));
	}

	handleEntryClick(order: Order) {
		kioskModule.updateOrderKey(order.OrderKey);
		kioskModule.updateOrder(order);
		this.$router.push({ name: 'AdultCheckin', params: { orderKey: order.OrderKey } });
	}

	get orders() {
		if (!this.searchInput) return this.orderEntities;
		return this.orderEntities.filter((o) => o.LastName.toLowerCase().includes(this.searchInput.toLowerCase()));
	}
}
