import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "si-input si-alt app-entry",
  ref: "entry"
}
const _hoisted_2 = { class: "bold party-name" }
const _hoisted_3 = { notranslate: "" }
const _hoisted_4 = { class: "checked-in" }
const _hoisted_5 = { class: "entry-activities" }
const _hoisted_6 = {
  key: 1,
  class: "app-confirmation flex-row"
}
const _hoisted_7 = { class: "confirm-text" }
const _hoisted_8 = {
  class: "bold",
  notranslate: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.showEntryData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "app-content flex-row",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEntryData = false))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.partyName), 1),
              _createTextVNode(" • ")
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.order.TotalPeopleCheckedIn) + "/" + _toDisplayString(_ctx.order.TotalPeople) + " Waivers Completed", 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activities, (activity, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                notranslate: ""
              }, _toDisplayString(activity.name) + " " + _toDisplayString(activity.time), 1))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, [
              _createTextVNode("Are you with the "),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.partyName), 1),
              _createTextVNode(" party?")
            ])
          ]),
          _createVNode(_component_OnClickOutside, {
            onTrigger: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showEntryData = true))
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "confirm-btn flex-center bold",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClick', _ctx.order))),
                style: _normalizeStyle(`height: ${_ctx.entryHeight - 2}px;`)
              }, "Confirm", 4)
            ]),
            _: 1
          })
        ]))
  ], 512))
}