
import { Order } from '@/common/types/Order.type';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { OnClickOutside } from '@vueuse/components';
import isBefore from 'date-fns/isBefore';
@Options({
	name: 'AppointmentEntry',
	components: { OnClickOutside },
	emits: ['onClick'],
})
export default class AppointmentEntry extends Vue {
	@Prop() order!: Order;
	showEntryData = true;
	entryHeight = 0;
	activities: {
		name: string;
		time: string;
		dateTime: Date;
	}[] = [];

	get partyName() {
		return `${this.order.FirstName.slice(0, 1).toUpperCase()}. ${this.order.LastName}`;
	}

	created() {
		// build sorted time slot object
		const orderTimeSlots: { name: string; time: string; dateTime: Date }[] = [];
		this.order.CheckInOrderEntities.forEach((e) => {
			e.CheckInOrderEntityTimes?.forEach((t) => {
				const timeAmPm = t.Time.split(' ')[1];
				const timeSplit = t.Time.split(' ')[0].split(':');
				const today = new Date();
				orderTimeSlots.push({
					name: e.EntityHierarchyName,
					time: t.Time,
					dateTime: new Date(today.getFullYear(), today.getMonth(), today.getUTCDate(), Number(timeSplit[0]) + timeAmPm == 'PM' ? 12 : 0, Number(timeSplit[1])),
				});
			});
		});
		this.activities = orderTimeSlots.sort((a, b) => {
			return isBefore(a.dateTime, b.dateTime) ? 1 : -1;
		});
	}

	mounted() {
		// store element height for confirmation
		this.entryHeight = (this.$refs.entry as HTMLElement).getBoundingClientRect().height;
	}
}
